.Motus {
    text-align: center;
    font-size: 30px;
}

.Motus .Responses {
    font-size: 35px;
}

.Motus input[type="text"] {
    border: 3px;
    font-size: 35px;
    background-color: rgb(206, 206, 206);
}

.Motus button {
    background-color: rgb(45, 74, 51);
    font-size: 35px;
    color: black;
  }